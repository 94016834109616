import { useEffect, useRef, useState } from "react";
import "./statistics.css";
import { useLocation } from "react-router-dom";

export default function Statistics() {
  const { pathname } = useLocation();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [hippodromeKey, setHipodromeKey] = useState("");

  // const [iframeLoaded, setIframeLoaded] = useState(false);
  // const sendMessage = (hippodrome_key: string) => {
  //   if (iframeRef.current && iframeLoaded) {
  //     iframeRef.current.contentWindow?.postMessage({ type: "SEND_HIPPO_KEY", hippodrome_key }, "*");
  //   }
  // };
  // const handleIframeLoad = () => {
  //   setIframeLoaded(true);
  // };

  useEffect(() => {
    const match = pathname.match(/\/([^\/]+)\/?$/);
    const hippodrome_key = match ? match[1] : null;

    if (hippodrome_key) {
      setHipodromeKey(hippodrome_key);
      // if (iframeLoaded) {
      //   const timeout = setTimeout(() => {
      //     sendMessage(hippodrome_key);
      //   }, 1000);

      //   return () => {
      //     clearTimeout(timeout);
      //   };
      // } else {
      //   const checkIframeLoaded = setInterval(() => {
      //     if (iframeLoaded) {
      //       clearInterval(checkIframeLoaded);
      //       const timeout = setTimeout(() => {
      //         sendMessage(hippodrome_key);
      //       }, 1000);

      //       // Clean up timeout on component unmount
      //       return () => {
      //         clearTimeout(timeout);
      //       };
      //     }
      //   }, 1000);
      // }
    }
  }, [pathname]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "RESIZE_IFRAME") {
        if (iframeRef.current) {
          iframeRef.current.style.height = `${event.data.height}px`;
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <iframe
      ref={iframeRef}
      // onLoad={handleIframeLoad}
      src={`https://tjk-statistics-web.bitrelic.io/?hipodrom=${hippodromeKey}`}
      style={{ width: "100%", border: "none", minHeight: "100vh" }}
      title="Resizable Iframe"
    />
  );
}
