import React, { useEffect, useState } from "react";
import { isMobile } from "../../App";
import { homeHippodrome } from "../../store/misc.store";
import { Link } from "react-router-dom";
import paths from "../../paths";
import iconDeposit from "../../assets/icons/deposit_icon.svg";
import programIcon from "../../assets/icons/program_icon.svg";
import tipsters from "../../assets/icons/footer_tipsters.svg";
import iconPossible from "../../assets/icons/icon_muhtemeller_agf.svg";
import iconComments from "../../assets/feather/message-square.svg";
import iconStatistic from "../../assets/icons/icon_istatistikler.svg";
import iconResult from "../../assets/icons/icon_sonuclar.svg";
import iconLiveStream from "../../assets/icons/live_stream_icon.svg";

export default function HomeShortcuts() {
  return (
    <>
      {!isMobile && homeHippodrome.keyword && (
        <div className="row home-shortcuts">
          <div className="col ">
            <div className="item">
              <Link to={`${paths.deposit.url}`}>
                <img alt={"para yatır"} src={iconDeposit} />
                <div>
                  <h2>PARA YATIR</h2>
                  Bahis yapmak için hemen para yatırın
                </div>
              </Link>
            </div>
          </div>
          <div className="col ">
            <div className="item">
              <Link
                to={`${paths.betting.url}/${homeHippodrome.keyword.toLowerCase()}${homeHippodrome._id}/muhtemeller`}
              >
                <img alt={"Muhtemel Agf"} src={iconPossible} />
                <div>
                  <h2>MUHTEMEL - AGF</h2>
                  Muhtemeller ve AGF verileri için tıklayın
                </div>
              </Link>
            </div>
          </div>
          <div className="col ">
            <div className="item">
              <Link to={`${paths.tipsterComments.url}`}>
                <img alt={"Yorumlar"} src={iconComments} />
                <div>
                  <h2>YORUMLAR</h2>
                  Koşulara ait uzman yorum ve analizleri
                </div>
              </Link>
            </div>
          </div>
          <div className="col ">
            <div className="item">
              <Link to={`${paths.results.url}/${homeHippodrome._date}/${homeHippodrome.keyword.toLowerCase()}/1`}>
                <img alt={"sonuçlar"} src={iconResult} />
                <div>
                  <h2>SONUÇLAR</h2>
                  At yarışı bülteni tüm detaylı bilgilerle
                </div>
              </Link>
            </div>
          </div>
          <div className="col ">
            <div className="item">
              <Link to={`${paths.statistics.url}/${homeHippodrome.keyword.toLowerCase()}/`}>
                <img alt={"İstatistikler"} src={iconStatistic} />
                <div>
                  <h2>İSTATİSTİKLER</h2>
                  Koşuların istatistiklerini incele.
                </div>
              </Link>
            </div>
          </div>
          {/* <div className="col ">
                    <div className="item">
                        <Link to={`${paths.stream.url}/0`}>
                            <img alt={"canlı izle"} src={iconLiveStream}/>
                            <div>
                                <h2>CANLI İZLE</h2>
                                Koşuları canlı olarak TJK TV'den takip et
                            </div>

                        </Link>
                    </div>
                </div> */}
        </div>
      )}

      {isMobile && homeHippodrome.keyword && (
        <div className="row home-shortcuts mx-0 p-0">
          <div className="col-3">
            <div className="item mobile-item">
              <Link
                className={"flex-column justify-content-center align-items-center"}
                to={`${paths.betting.url}/${homeHippodrome.keyword.toLowerCase()}${homeHippodrome._id}/muhtemeller`}
              >
                <img alt={"muhtemeller"} src={iconStatistic} />

                <h2 className={"text-center"}>
                  MUHTEMEL
                  <br />
                  AGF
                </h2>
              </Link>
            </div>
          </div>
          <div className="col-3">
            <div className="item mobile-item">
              <Link
                className={"flex-column justify-content-center align-items-center"}
                to={`${paths.tipsterCoupons.url}`}
              >
                <img alt={"program"} src={tipsters} />

                <h2 className={"text-center"}>HAZIR KUPONLAR</h2>
              </Link>
            </div>
          </div>

          <div className="col-3">
            <div className="item mobile-item">
              <Link
                className={"flex-column justify-content-center align-items-center"}
                to={`${paths.tipsterComments.url}`}
              >
                <img alt={"yorumlar"} src={iconComments} />

                <h2 className={"text-center"}>YORUMLAR</h2>
              </Link>
            </div>
          </div>

          <div className="col-3">
            <div className="item mobile-item">
              <Link className={"flex-column justify-content-center align-items-center"} to={`${paths.campaigns.url}`}>
                <img alt={"kampanya"} src={iconResult} />

                <h2 className={"text-center"}>KAMPANYA</h2>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
