import "./hippodrome.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { availableHippodromes, DailyRaces, racesActionSubject } from "../../store/bulletin.store";
import { Hippodrome } from "../../models/bulletin.models";
import { useEffect } from "react";
import { getDateHumanize, useForceUpdate } from "../../services/misc.functions";
import { Link, useLocation } from "react-router-dom";
import paths from "../../paths";
import { homeHippodrome, homeHippodromeSubject, path } from "../../store/misc.store";
import { Navigation, Pagination } from "swiper/modules";
import { isMobile } from "../../App";

export function HippodromeWidget() {
  const forceUpdate = useForceUpdate();
  const { pathname } = useLocation();

  useEffect(() => {
    let racesActionSubject_subscription = racesActionSubject.subscribe((res: any) => {
      if (!homeHippodrome?._id) {
        changeSelectedHippodrome(availableHippodromes[0]);
      }
      forceUpdate();
    });
    return () => {
      racesActionSubject_subscription.unsubscribe();
    };
  });

  const changeSelectedHippodrome = (hippodrome: any) => {
    Object.assign(homeHippodrome, hippodrome);
    homeHippodromeSubject.next(hippodrome);
    forceUpdate();
  };

  // fixme ana sayfa hariç görünmemeli
  useEffect(() => {
    forceUpdate();
  }, [pathname]);

  if (path.root !== "/") {
    return <></>;
  }

  return (
    <div className="container hippodrome-container p-0">
      <div className="hippodrome-widgets">
        {DailyRaces.length === 0 && (
          <>
            <Swiper
              spaceBetween={isMobile ? 5 : 10}
              modules={[Navigation, Pagination]}
              navigation={
                isMobile
                  ? false
                  : {
                      prevEl: ".prev",
                      nextEl: ".next",
                    }
              }
              pagination={isMobile ? { clickable: true } : false}
              onSlideChange={(swiper: any) => {
                if (isMobile) {
                  changeSelectedHippodrome(availableHippodromes[swiper.activeIndex]);
                }
              }}
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                960: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                // when window width is >= 640px
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
              }}
            >
              <SwiperSlide>
                <div className={`item skeleton`}>
                  <h2>
                    <span></span>
                  </h2>
                  <div className="description">
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`item skeleton`}>
                  <h2>
                    <span></span>
                  </h2>
                  <div className="description">
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`item skeleton`}>
                  <h2>
                    <span></span>
                  </h2>
                  <div className="description">
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`item skeleton`}>
                  <h2>
                    <span></span>
                  </h2>
                  <div className="description">
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`item skeleton`}>
                  <h2>
                    <span></span>
                  </h2>
                  <div className="description">
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`item skeleton`}>
                  <h2>
                    <span></span>
                  </h2>
                  <div className="description">
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                    <div>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </>
        )}

        <Swiper
          spaceBetween={isMobile ? 5 : 10}
          modules={[Navigation, Pagination]}
          navigation={
            isMobile
              ? false
              : {
                  prevEl: ".prev",
                  nextEl: ".next",
                }
          }
          pagination={isMobile ? { clickable: true } : false}
          onSlideChange={(swiper: any) => {
            if (isMobile) {
              changeSelectedHippodrome(availableHippodromes[swiper.activeIndex]);
            }
          }}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            960: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            // when window width is >= 640px
            1280: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          {availableHippodromes
            .filter(
              (el: any) =>
                new Date(el.date).getDate() === new Date().getDate() ||
                (new Date(el.date).getDate() !== new Date().getDate() && el.wager_details.length > 0)
            )
            .map((hippodrome: Hippodrome, keyHippodrome: number) => {
              return (
                <SwiperSlide key={`hippodrome-widget-slider-${keyHippodrome}`}>
                  <div className={`item d-flex cursor-pointer ${homeHippodrome.id === hippodrome.id ? "active" : ""}`}>
                    <div className={isMobile ? "w-50" : "w-100"}>
                      <h2
                        className={"nowrap-ellipsis"}
                        onClick={() => {
                          changeSelectedHippodrome(hippodrome);
                        }}
                      >
                        {hippodrome.location}
                        <br />
                        {!hippodrome.status && <span className="float-end">{getDateHumanize(hippodrome.date)}</span>}
                        {hippodrome.nextRun && (
                          <span>
                            {hippodrome.nextRun.no}. Koşu - {hippodrome.nextRun.time}
                          </span>
                        )}
                      </h2>

                      {!isMobile && homeHippodrome.id === hippodrome.id ? (
                        <>
                          {hippodrome.wager_details.length > 0 ? (
                            <Link
                              className="btn btn-sm w-100 fw-semibold mt-2 rounded-1 btn-warning"
                              to={`${paths.betting.url}/${hippodrome.keyword.toLowerCase()}${hippodrome._id}`}
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}
                            >
                              BAHİS YAP
                            </Link>
                          ) : (
                            <Link
                              className="btn btn-sm w-100 fw-semibold mt-2 rounded-1 btn-warning"
                              to={`${paths.results.url}/${hippodrome._date}/${hippodrome.keyword.toLowerCase()}/1`}
                              onClick={() => {
                                window.scrollTo(0, 0);
                              }}
                            >
                              SONUÇLAR
                            </Link>
                          )}
                        </>
                      ) : (
                        <>
                          <div
                            className="description"
                            onClick={() => {
                              changeSelectedHippodrome(hippodrome);
                            }}
                          >
                            <Weather hippodrome={hippodrome}></Weather>
                          </div>
                        </>
                      )}
                    </div>
                    {isMobile && (
                      <div className="w-50 ps-3">
                        {hippodrome.wager_details.length > 0 ? (
                          <Link
                            className="btn btn-sm w-100 fw-semibold mb-0 rounded-1 btn-warning"
                            to={`${paths.betting.url}/${hippodrome.keyword.toLowerCase()}${hippodrome._id}`}
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}
                          >
                            BAHİS YAP
                          </Link>
                        ) : (
                          <Link
                            className="btn btn-sm w-100 fw-semibold mb-0 rounded-1 btn-warning"
                            to={`${paths.results.url}/${hippodrome._date}/${hippodrome.keyword.toLowerCase()}/1`}
                            onClick={() => {
                              window.scrollTo(0, 0);
                            }}
                          >
                            SONUÇLAR
                          </Link>
                        )}
                        <Link
                          className="btn btn-sm w-100 fw-semibold mt-1 mb-1 rounded-1 btn-secondary text-nowrap"
                          to={`${paths.betting.url}/${hippodrome.keyword.toLowerCase()}${hippodrome._id}/muhtemeller`}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          MUHTEMELLER / AGF
                        </Link>
                        <Link
                          className="btn btn-sm w-100 fw-semibold mt-1 mb-1 rounded-1 btn-secondary text-nowrap"
                          to={`${paths.statistics.url}/${hippodrome.keyword.toLowerCase()}`}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          İSTATİSTİKLER
                        </Link>
                        {/* <Link
                          className="btn btn-sm w-100 fw-semibold mt-0 rounded-1 btn-secondary"
                          to={`${paths.betting.url}/${hippodrome.keyword.toLowerCase()}${hippodrome._id}/agf`}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          AGF
                        </Link> */}
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>

        {!isMobile && (
          <>
            <div className="swiper-button-prev prev"></div>
            <div className="swiper-button-next next"></div>
          </>
        )}
      </div>
    </div>
  );
}

export function Weather(prop: any) {
  const hippodrome = prop.hippodrome;
  return (
    <>
      <div>
        <span>
          Nem: <b>%{hippodrome.weather.humidity}</b>
        </span>
        <span>{hippodrome.nextRun ? <b>{hippodrome.nextRun.distance}m</b> : <br />}</span>
      </div>
      <div>
        <span>
          Sıcaklık: <b>{hippodrome.weather.temperature}°C</b>
        </span>
        <span>
          <b>{hippodrome.nextRun && hippodrome.nextRun.course}</b>
        </span>
      </div>
    </>
  );
}
