import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import couponCover from "../../../assets/img/win-point-scracth.png";
import couponBg from "../../../assets/img/inner-scratch.png";
// @ts-ignore
// import loadingGif from "../../../assets/img/loading-loader.gif";
import ScratchCard from "react-scratchcard";
import iconClose from "../../../assets/feather/x-circle.svg";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useNavigate, useParams } from "react-router";

import { Simulate } from "react-dom/test-utils";
import load = Simulate.load;
import Confetti from "react-confetti";
import { exceptionModal, loadingAnimation, useForceUpdate } from "../../../services/misc.functions";
import { auth } from "../../../store/auth.store";
import { isMobile } from "../../../App";
import { random } from "lodash";
// import emptyImage from "../../../assets/icons/empty_screen.svg";
import * as path from "path";
import paths from "../../../paths";
import { ApiService } from "../../../services/http.service";
import { env } from "../../../constants/global.vars";
import { scratchCards } from "../../../store/raffle.store";

let timerSliderChanger: any = null;
let xhrMetaData: any = {};

export function ScratchCardMyCards() {
  const [dataList, setDataList] = useState<any>(null);
  const [showDrawModal, setShowDrawModal] = useState<any>(null);
  const [dataFilterTab, setDataFilterTab] = useState<any>(0);
  const [sliderRef, setSliderRef] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [scratchedCardCount, setScratchedCardCount] = useState<any>(0);
  const [showTotalWin, setShowTotalWin] = useState<any>(false);
  const [totalPayout, setTotalPayout] = useState<any>(0);
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  let params = useParams();
  const [confetti, setConfetti] = useState<any>(false);

  useEffect(() => {
    if (isMobile) {
      const bottomBar = document.getElementById("mobile-footer-menu");
      const headers = document.getElementsByTagName("header");

      // Hide elements
      if (bottomBar) {
        bottomBar.style.display = "none";
      }

      if (headers.length > 0) {
        headers[0].style.display = "none";
      }

      // Cleanup function to restore elements
      return () => {
        if (bottomBar) {
          bottomBar.style.display = "flex";
        }

        if (headers.length > 0) {
          headers[0].style.display = "flex";
        }
      };
    }
  }, []);

  useEffect(() => {
    getMyCards();
  }, [dataFilterTab]);

  useEffect(() => {
    // console.log(params.draw_id);
    if (params.draw_id) {
      showCards(params.draw_id);
    }
  }, []);

  const getMyCards = () => {
    const api = new ApiService();
    let payload: any = {
      has_scratch: false,
    };

    if (dataFilterTab === 0) {
      payload.has_scratch = false;
      payload.status = null;
    }
    if (dataFilterTab === 2) {
      payload.has_scratch = true;
      payload.status = 2;
    }
    if (dataFilterTab === 3) {
      payload.has_scratch = true;
      payload.status = 3;
    }

    api.start("post", env.accounting_api + "/somos/api/kazi-kazan/kuponlarim/", payload, true).then((res: any) => {
      setDataList(res.data);
      // console.log(res)
    });
  };

  const showCards = (drawId: any) => {
    const api = new ApiService();
    loadingAnimation(true);
    // console.log(drawId)
    let payload: any = {
      // has_scratch: false,
      type: "get_draw",
      draw_id: drawId,
    };

    if (dataFilterTab === 0) {
      payload.has_scratch = false;
      setConfetti(false);
    }
    if (dataFilterTab === 2) {
      payload.has_scratch = true;
      payload.status = 2;
      setConfetti(false);
    }
    if (dataFilterTab === 3) {
      payload.has_scratch = true;
      payload.status = 3;
      setConfetti(false);
    }

    api
      .start("post", env.accounting_api + "/somos/api/kazi-kazan/kuponlarim/", payload, true)
      .then((res: any) => {
        // console.log(res);
        if (res.data.length > 0) {
          setShowDrawModal(res.data);
        } else {
          window.history.pushState(null, "", paths.scratch.url + "/kartlarim");
        }
      })
      .finally(() => {
        loadingAnimation(false);
      });
  };

  let screenWidth = window.innerWidth;
  if (screenWidth > 768) {
    screenWidth = 375;
  }
  let cardHeight = ((screenWidth - 40) / 335) * 406;

  const swiper = useSwiper();
  const handleScratchComplete = (c: any) => {
    const api = new ApiService();
    let payload: any = {
      type: "scratch",
      coupons: String(c.id),
    };
    api.start("post", env.accounting_api + "/somos/api/kazi-kazan/kazi/", payload, true).then((res: any) => {
      if (res && res.status) {
        let d: any = dataList.find((e: any) => e.draw_id === c.draw_id);
        if (d) {
          d.not_scratched_count -= 1;
          setConfetti(false);
        }

        c.has_scratched = true;
        // timerSliderChanger = setTimeout(() => {
        //     sliderRef.slideNext();
        // }, 3000);

        c.selections.map((s: any, skey: number) => {
          let nonUnique = c.selections.filter((o: any) => {
            if (o.value === s.value) {
              return o;
            }
          }).length;
          if (nonUnique === 3) {
            setTotalPayout(totalPayout + Number(s.value));
            c.is_won = true;
            c.won_amount = s.value;
            setConfetti(true);
            setTimeout(() => {
              setConfetti(false);
            }, 6000);
          }
        });
        forceUpdate();
      }
    });
    setScratchedCardCount(scratchedCardCount + 1);
    setTimeout(() => {
      if (scratchedCardCount + 1 == showDrawModal.length) {
        setShowTotalWin(true);
      }
    }, 3000);
  };

  const scratchAll = () => {
    const api = new ApiService();
    setLoading(true);
    var couponIds = showDrawModal.map((el: any, key: number) => {
      return el.id;
    });
    var totalWon = 0;

    let payload: any = {
      type: "scratch",
      coupons: couponIds.join(","),
    };
    api
      .start("post", env.accounting_api + "/somos/api/kazi-kazan/kazi/", payload, true)
      .then((res: any) => {
        if (res && res.status) {
          showDrawModal.map((c: any, key: number) => {
            c.has_scratched = true;
            c.selections.map((s: any, skey: number) => {
              let nonUnique = c.selections.filter((o: any) => {
                if (o.value === s.value) {
                  return o;
                }
              }).length;
              if (nonUnique === 3) {
                c.is_won = true;
                c.won_amount = s.value;
              }
            });
            if (c.is_won) {
              totalWon += Number(c.won_amount);
            }
          });

          setScratchedCardCount(showDrawModal.length);
          setShowTotalWin(true);
          setTotalPayout(totalWon);
          if (totalWon > 0) {
            setConfetti(true);
            setTimeout(() => {
              setConfetti(false);
            }, 6000);
          }
          forceUpdate();
          setLoading(false);
        }
      })
      .catch((e: any) => {
        exceptionModal(e);
      });
  };

  //   if (!auth.member?.id) {
  //     loginRequired(navigate);
  //     return <LoginRequired></LoginRequired>;
  //   }

  return (
    <>
      <div style={{ zIndex: 99999 }}>{confetti ? <Confetti /> : <></>}</div>
      <div className="col-lg-12 ">
        <div className={"draw-filter-wrapper"}>
          {!isMobile && (
            <div className="float-start">
              <h4>
                Kazı Kazan Kartlarım
                <span>
                  {xhrMetaData?.count} çekiliş, {dataList?.length} adet gösterilen.
                </span>
              </h4>
            </div>
          )}
          <div className="float-lg-end">
            <button
              className={`btn btn-sm me-1 btn-outline-primary ${dataFilterTab === 0 && "active"}`}
              onClick={() => {
                setDataFilterTab(0);
              }}
            >
              Kazınmamış
            </button>
            <button
              className={`btn btn-sm me-1 btn-outline-primary ${dataFilterTab === 2 && "active"} `}
              onClick={() => {
                setDataFilterTab(2);
              }}
            >
              Kazanan
            </button>
            <button
              className={`btn btn-sm me-1 btn-outline-primary ${dataFilterTab === 3 && "active"} `}
              onClick={() => {
                setDataFilterTab(3);
              }}
            >
              Kaybeden
            </button>
          </div>
        </div>
      </div>

      {dataList &&
        dataList.map((draw: any, key: number) => {
          return (
            <div className="col-12 mb-3 mobile-row mt-2 col-lg-6" key={`my-scratch-card-${key}`}>
              <div className="scratch-card">
                <div className="head">
                  {dataFilterTab === 0 && (
                    <>
                      <button
                        className="btn btn-sm btn-primary fw-medium float-end"
                        onClick={() => {
                          showCards(draw.draw_id);
                        }}
                      >
                        Kazı
                      </button>
                    </>
                  )}
                  {dataFilterTab === 2 && (
                    <>
                      <button
                        className="btn btn-sm btn-warning fw-medium float-end"
                        onClick={() => {
                          showCards(draw.draw_id);
                        }}
                      >
                        Kartları Göster
                      </button>
                    </>
                  )}
                  {dataFilterTab === 3 && (
                    <>
                      <button
                        className="btn btn-sm btn-warning fw-medium float-end"
                        onClick={() => {
                          showCards(draw.draw_id);
                        }}
                      >
                        Kartları Göster
                      </button>
                    </>
                  )}

                  <span>{draw.draw != null ? draw.draw?.title_abbreviated : "Kazı Kazan"}</span>
                  <h2>Büyük Ödül: {draw.big_prize || "-"}</h2>
                </div>
                <div className="foot">
                  <div className="row text-secondary">
                    <div className="col-12 text-end">
                      {dataFilterTab === 3 && <>Satın Alınan Bilet: {draw.total_count} adet</>}
                      {dataFilterTab === 2 && (
                        <>
                          Toplam Kazanç:
                          <span className="text-primary fw-bold">{draw.total_won_amount}₺</span>
                        </>
                      )}
                      {dataFilterTab === 0 && (
                        <>
                          Kazınmamış Bilet:
                          <span className="text-primary fw-bolder">{draw.not_scratched_count} adet</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      {dataList && dataList.length === 0 && (
        <div className={"d-flex flex-column justify-content-center align-items-center mt-5"}>
          {/* <img className={"h-25"} src={emptyImage} /> */}
          <div className="text-center py-5 fw-bold">
            {dataFilterTab === 0 && "Kazınmamış "}
            {dataFilterTab === 1 && "Kazanan "}
            {dataFilterTab === 2 && "Kaybeden "}
            kartınız bulunmamaktadır.
          </div>
          <div
            className={"btn btn-primary"}
            onClick={() => {
              navigate(paths.scratch.url);
              window.location.reload();
            }}
          >
            Oynamaya Devam Et
          </div>
        </div>
      )}

      <Modal
        ariaHideApp={false}
        isOpen={showDrawModal !== null}
        onAfterOpen={() => {
          document.body.style.overflow = "hidden";
        }}
        onRequestClose={() => {
          document.body.style.overflow = "unset";
          getMyCards();
          setLoading(false);
          setShowDrawModal(null);
          setShowTotalWin(false);
          setScratchedCardCount(0);
          window.history.pushState(null, "", paths.scratch.url + "/kartlarim");
        }}
        className={"scratch-card-modal"}
        style={{
          content: {
            marginLeft: isMobile ? "-40px" : "calc(50% - 275px)",
            width: isMobile ? "100%" : screenWidth + 40,
            height: "calc(100%)",
            paddingTop: "10px",
            maxHeight: "525px",
            paddingBottom: "10px",
          },
          overlay: {
            zIndex: 2,
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(10px)",
          },
        }}
        contentLabel=""
      >
        <a
          className="modal-close"
          onClick={() => {
            document.body.style.overflow = "unset";
            getMyCards();
            setShowDrawModal(null);
            setShowTotalWin(false);
            window.history.pushState(null, "", paths.scratch.url + "/kartlarim");
            setScratchedCardCount(0);
            setLoading(false);
          }}
        >
          <img src={iconClose} />
        </a>

        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <div className="card rounded-4 mt-2" style={{ backgroundColor: "transparent" }}>
                <div className="card-header">
                  <h2 className={"text-center fs-4"} style={{ color: "#ffffff" }}>
                    Lütfen Bekleyin Biletleriniz Kazınıyor
                  </h2>
                </div>
                <div className="card-body d-flex justify-content-center">
                  {/* <img src={loadingGif} style={{ width: "50%" }} /> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {showTotalWin ? (
              <>
                {totalPayout > 0 ? (
                  <h2 className="text-center fw-bold fs-4">Tebrikler</h2>
                ) : (
                  <h2 className="text-center fw-bold fs-4">Tekrar Dene</h2>
                )}
                <div className="scratch-card winner">
                  <div className="body p-4">
                    {totalPayout > 0 ? (
                      <div className="desciption">
                        <span>Toplam</span>
                        <h2>{totalPayout} ₺</h2>
                        <span>NP Puan </span>
                        <span>Kazandınız</span>
                      </div>
                    ) : (
                      <div className="desciption">
                        {/*<h2>Kaybettiniz ☹️</h2>*/}
                        <h2>Biletinize ikramiye çıkmadı</h2>
                      </div>
                    )}
                  </div>
                  {/*<div className="foot">*/}

                  {/*   <p>Kazanılan tutar hesabına NP Puan Olarak yatırılmıştır.</p>*/}

                  {/*</div>*/}
                </div>
                {totalPayout > 0 && (
                  <div className="card rounded-4 mt-2">
                    <div className="card-header">
                      <h2 className={"won-card-header"}>Kazanan Kart Listesi</h2>
                    </div>
                    <div className="card-body won-tickets-area">
                      {showDrawModal &&
                        showDrawModal
                          .filter((el: any) => el.is_won == true)
                          .map((el: any, key: any) => {
                            return (
                              <div key={"won-tickets-" + key.toString()} className={"won-ticket-row"}>
                                <span>#{el.id}</span>
                                <span>#{el.won_amount}</span>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                )}
                <button
                  className="btn btn-warning w-100 mt-3 fw-bold"
                  onClick={() => {
                    document.body.style.overflow = "unset";
                    setShowDrawModal(null);
                    setShowTotalWin(false);
                    getMyCards();
                    setScratchedCardCount(0);
                    setLoading(false);
                    setConfetti(false);
                    navigate(paths.scratch.url);
                  }}
                  id="btn_submit"
                >
                  Başka Kart Al
                </button>
                <button
                  className="btn btn-primary w-100 mt-3"
                  onClick={() => {
                    document.body.style.overflow = "unset";
                    setShowDrawModal(null);
                    setShowTotalWin(false);
                    getMyCards();
                    setScratchedCardCount(0);
                    setLoading(false);
                    setConfetti(false);
                    window.history.pushState(null, "", paths.scratch.url + "/kartlarim");
                  }}
                  id="btn_submit"
                >
                  Kartlarım
                </button>
              </>
            ) : (
              <>
                {showDrawModal && (
                  <h2 className="text-center fw-bold fs-6">
                    {/* <span style={{
                                    fontSize: 14,
                                    display: 'block'
                                }}>{showDrawModal[0].draw != null ?  showDrawModal[0].draw.title_abbreviated : 'Kazı Kazan'}</span> */}
                    Büyük Ödül:
                    {showDrawModal[0].draw != null ? showDrawModal[0].draw.big_prize || "-" : ""}
                  </h2>
                )}
                {showDrawModal && dataFilterTab === 0 && (
                  <h2 className="text-center fw-bold fs-6">
                    {scratchedCardCount} / {showDrawModal.length}
                  </h2>
                )}

                {showDrawModal && (
                  <Swiper
                    spaceBetween={30}
                    id={`card-slider`}
                    allowTouchMove={false}
                    onSlideChange={(e: any) => {
                      clearTimeout(timerSliderChanger);
                      let ex: any = document.querySelectorAll("#card-slider");
                      let wrapper: any = document.querySelectorAll("#card-slider .swiper-wrapper");
                      wrapper[0].style.marginLeft = `unset`;
                      wrapper[0].style.transform = `translate3d(${
                        ex[0].offsetWidth * -1 * e.activeIndex
                      }px, 0px, 0px);`;
                      setTimeout(() => {
                        // console.log(ex[0].offsetWidth)
                        wrapper[0].style.marginLeft = `${ex[0].offsetWidth * -1 * e.activeIndex}px`;
                        wrapper[0].style.transform = "unset";
                      }, 400);
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                    }}
                    onInit={(ev: any) => {
                      // console.log(ev)
                      setSliderRef(ev);
                    }}
                  >
                    {showDrawModal &&
                      showDrawModal.map((el: any, key: number) => {
                        let nonUnique: any = 0;
                        let prizePayout: any = 0;

                        if (el.has_scratched === false || dataFilterTab === 0) {
                          return (
                            <SwiperSlide id={`card-slider-items-${key}`} key={`card-slider-items-${key}`}>
                              <div className="px-3 d-flex justify-content-center flex-column align-items-center">
                                {/* @ts-ignore */}
                                <ScratchCard
                                  {...{
                                    width: isMobile ? screenWidth - 77 : screenWidth - 40,
                                    height: isMobile ? cardHeight - 110 : cardHeight - 90,
                                    image:
                                      el.draw__big_prize_photo !== null || el.draw__big_prize_photo !== ""
                                        ? env.accounting_api + "/media/" + el.draw__big_prize_photo
                                        : couponCover,
                                    finishPercent: 70,
                                    onComplete: () => handleScratchComplete(el),
                                  }}
                                >
                                  <div className="scratch-off-card">
                                    <img style={{ height: cardHeight - 90 }} src={couponBg} />
                                    <div className="row">
                                      {el.selections.map((s: any, skey: number) => {
                                        let nonUnique = el.selections.filter((o: any) => {
                                          if (o.value === s.value) {
                                            return o;
                                          }
                                        }).length;
                                        if (nonUnique === 3) {
                                          prizePayout = Number(s.value);
                                        }
                                        return (
                                          <div
                                            key={`${skey}-selection-${key}`}
                                            className={`col-6 ${
                                              nonUnique === 3 && el.has_scratched === true
                                                ? "won"
                                                : el.has_scratched === true && nonUnique == 2 && prizePayout == 0
                                                ? "semi-won"
                                                : el.has_scratched === false && s.has_scratched !== true
                                                ? "non-scratched"
                                                : ""
                                            }`}
                                            style={{
                                              height: (cardHeight - 90) / 3,
                                            }}
                                          >
                                            {Number(s.value).toFixed(0)}₺
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </ScratchCard>
                                {/*<div className={"mt-2"}>*/}
                                {/*    <button className="btn btn-info w-100 mt-1 fw-bold"*/}
                                {/*            disabled={el.has_scratched === false}*/}
                                {/*            style={{color:'#ffffff'}}*/}
                                {/*            onClick={() => {*/}
                                {/*                sliderRef.slideNext();*/}
                                {/*            }} id="btn_submit">Sıradaki Karta Geç*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                                {showDrawModal && scratchedCardCount != showDrawModal.length && dataFilterTab === 0 && (
                                  <button
                                    className="btn btn-primary w-100 mt-2 fw-bold"
                                    onClick={() => {
                                      if (el.has_scratched == false) {
                                        scratchAll();
                                      } else {
                                        sliderRef.slideNext();
                                        setConfetti(false);
                                      }
                                    }}
                                    id="btn_submit"
                                  >
                                    {el.has_scratched == false ? "Tümünü Kazı" : "Sıradaki Karta Geç"}
                                  </button>
                                )}
                              </div>
                            </SwiperSlide>
                          );
                        } else {
                          return (
                            <SwiperSlide id={`card-slider-items-${key}`} key={`card-slider-items-${key}`}>
                              <h2 className="text-center fw-bold fs-6">
                                {key + 1} / {showDrawModal.length}
                              </h2>

                              <div className="px-3 d-flex justify-content-center flex-column align-items-center">
                                <div className="scratch-off-card" style={{ height: cardHeight - 90 }}>
                                  <img src={couponBg} style={{ height: cardHeight - 90 }} />
                                  <div className="row">
                                    {el.selections.map((s: any, skey: number) => {
                                      let nonUnique = el.selections.filter((o: any) => {
                                        if (o.value === s.value) {
                                          return o;
                                        }
                                      }).length;
                                      if (nonUnique === 3) {
                                        prizePayout = Number(s.value);
                                      }
                                      return (
                                        <>
                                          <div
                                            key={`${skey}-selection-${key}`}
                                            className={`col-6 ${nonUnique}  ${
                                              el.status === 3 && el.has_scratched === true && nonUnique !== 3 && "lost"
                                            } ${
                                              el.status === 3 &&
                                              el.has_scratched === true &&
                                              nonUnique === 2 &&
                                              "semi-won"
                                            } ${
                                              nonUnique === 3 && el.has_scratched === true
                                                ? "won"
                                                : el.has_scratched === false && s.has_scratched !== true
                                                ? "non-scratched"
                                                : ""
                                            }`}
                                            style={{
                                              height: (cardHeight - 90) / 3,
                                            }}
                                          >
                                            {Number(s.value).toFixed(0)}₺
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              <button
                                type={"button"}
                                className="btn btn-primary w-100 mt-2 fw-bold"
                                onClick={() => {
                                  sliderRef.slideNext();
                                  setConfetti(false);
                                  forceUpdate();
                                }}
                              >
                                Sıradaki Karta Geç
                              </button>
                            </SwiperSlide>
                          );
                        }
                      })}
                  </Swiper>
                )}

                {/*{showDrawModal && scratchedCardCount != showDrawModal.length && dataFilterTab === 0 &&*/}
                {/*    <button className="btn btn-success w-100 mt-2 fw-bold" onClick={() => {*/}
                {/*        scratchAll();*/}
                {/*    }} id="btn_submit">Tümünü Kazı*/}
                {/*    </button>}*/}
                {showDrawModal && (
                  <h2 className="text-center fw-bold fs-5 mt-3">
                    {/* <span style={{fontSize: 14, display: 'block'}}>Aynı 3 sayıyı bul, ödülü kazan!</span> */}
                  </h2>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
}
