import React, { useEffect, useState } from "react";
import iconInfo from "../../../assets/feather/info.svg";
import "./scratch.cards.css";

import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";
import _ from "lodash";
import iconClose from "../../../assets/feather/x-circle.svg";
import couponBg from "../../../assets/img/inner-scratch.png";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import couponCover from "../../../assets/img/win-point-scracth.png";
import { exceptionModal, gtmViewItem, loadingAnimation, useForceUpdate } from "../../../services/misc.functions";
import { isMobile } from "../../../App";
import { modalServiceSubject } from "../../../services/modal.service";
import { auth } from "../../../store/auth.store";
import ScratchCard from "react-scratchcard";
import paths from "../../../paths";
import { ApiService } from "../../../services/http.service";
import { env } from "../../../constants/global.vars";
import { scratchCards } from "../../../store/raffle.store";
import { ScratchCardMyCards } from "./scratch.my.cards";
import eSansWhite from "../../../assets/icons/e-sans-white.svg";
import GameSelectWidget from "../widgets/game_select_widget";
import backButton from "../../../assets/feather/chevron-left.svg";

const tabs = [
  { value: undefined, label: "Kazı Kazan" },
  { value: "/kazi-kazan/kartlarim", label: "Kartlarım" },
  { value: "/kazi-kazan/kurallar", label: "Kurallar" },
];

export function ScratchCards() {
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [shownDraw, setShownDraw] = useState<any>(null);
  let params = useParams();

  const [selectedTab, setSelectedTab] = useState<any>(tabs[0]);
  const handleSelect = (e: any) => {
    if (e.target.id) {
      setSelectedTab(tabs[e.target.id]);
      const index = tabs[e.target.id]?.value;

      if (index !== undefined) {
        navigate(index);
      } else {
        navigate(paths.scratch.url);
      }
    }
  };

  useEffect(() => {
    if (params.subpage === undefined) {
      gtmViewItem([{ code: "kazi_kazan", name: "KAZI Kazan" }], "Oyunlar");
      getDraws();
    }
  }, [pathname]);

  useEffect(() => {
    if (isMobile) {
      const bottomBar = document.getElementById("mobile-footer-menu");
      const headers = document.getElementsByTagName("header");

      // Hide elements
      if (bottomBar) {
        bottomBar.style.display = "none";
      }

      if (headers.length > 0) {
        headers[0].style.display = "none";
      }

      // Cleanup function to restore elements
      return () => {
        if (bottomBar) {
          bottomBar.style.display = "flex";
        }

        if (headers.length > 0) {
          headers[0].style.display = "flex";
        }
      };
    }
  }, []);

  const getDraws = async () => {
    const api = new ApiService();
    loadingAnimation(true);
    return await api
      .start("get", env.accounting_api + "/somos/api/kazi-kazan/cekilis/", null, false)
      .then((res: any) => {
        loadingAnimation(false);
        // console.log(res)
        scratchCards.splice(0, scratchCards.length);
        scratchCards.push(...res.data);
        forceUpdate();
      })
      .catch((e: any) => {
        exceptionModal(e);
        setTimeout(() => {
          getDraws();
        }, 3000);
      });
  };

  const loadMore = (e: any) => {
    e.target.disabled = true;
    getDraws().finally(() => {
      e.target.disabled = false;
    });
  };

  return (
    <>
      {isMobile && (
        <div className="row mb-2">
          <div className="col-3 d-flex align-items-center justify-content-center">
            <img
              src={backButton}
              height={25}
              style={{ marginRight: "0.5rem", position: "absolute", left: "10px", filter: "invert(100%)" }}
              onClick={() => navigate(paths.games.url)}
            />
          </div>

          <div className="col-6 d-flex flex-column justify-content-center align-items-center">
            <div className={"d-flex justify-content-center align-items-center mt-2 me-4"}>
              <img src={eSansWhite} height={20} style={{ marginRight: "0.5rem" }} />
              <h2 className={"text-white mb-0"} style={{ fontSize: "16px" }}>
                Şans Oyunları
              </h2>
            </div>
            <GameSelectWidget game_name="Kazı Kazan" />
          </div>

          <div className="col-3 game-user-balance">
            <div className="lines">
              <span>{auth.member.balance_debit}₺</span>
              <span>{Number(auth.member.balance_point).toFixed(2)}P</span>
            </div>
          </div>
        </div>
      )}

      {!isMobile && (
        <div className="row gap-4 flex-nowrap p-0 m-0">
          <div className={`page-widget p-0`}>
            <svg style={{ display: "none" }}>
              <defs>
                <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
              </defs>
            </svg>
            <div className={"tab-scroll"}>
              {/* Tab Header */}
              <ul className="nav nav-tabs">
                {tabs.map(({ value, label }, index) => {
                  return (
                    <li
                      key={`raffle-tabs-${index}`}
                      className={`nav-item raffle-item ${selectedTab.value === value ? "active" : ""}`}
                      onClick={label ? handleSelect : () => {}}
                    >
                      <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                        <use xlinkHref="#tabshape"></use>
                      </svg>
                      <a className="nav-link" id={`${index}`}>
                        {label}
                      </a>
                      <svg className="right" viewBox="0 0 80 60" preserveAspectRatio="none">
                        <use xlinkHref="#tabshape"></use>
                      </svg>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* Tab Content */}
            <div className="p-2">
              <div className={"container"}>
                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                  {params.subpage === undefined && (
                    <>
                      {scratchCards.map((card: any, key: number) => {
                        return (
                          <ScratchCardWidget
                            key={`scratch-card-${key}`}
                            setShownDraw={setShownDraw}
                            draw={card}
                          ></ScratchCardWidget>
                        );
                      })}
                    </>
                  )}

                  {params.subpage === "kartlarim" && (
                    <>
                      <ScratchCardMyCardsImported></ScratchCardMyCardsImported>
                    </>
                  )}

                  {params.subpage === "kurallar" && (
                    <>
                      <ScratchCardRules></ScratchCardRules>
                    </>
                  )}

                  <div className="text-center">
                    <div id="id_loaderAnimation" className="loader"></div>
                    <button
                      id="id_datalist_loadmore"
                      onClick={loadMore}
                      type="button"
                      className="btn rounded-pill btn-outline-primary"
                    >
                      Devamını Yükle
                    </button>
                  </div>
                  <div className={"col-12 col-mobile-12 col-lg-12 mb-3"}>{/* <GameInfo code={"kazi_kazan"} /> */}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div className={"col-12"}>
          <div className={"mobile-tab-widget"}>
            <div className={"row"}>
              <div className={"col-4"}>
                <div
                  className={`draw-tab-item ${pathname.endsWith(paths.scratch.url) ? "selected" : "border"}`}
                  onClick={() => {
                    navigate(paths.scratch.url);
                  }}
                >
                  <div>KAZI KAZAN</div>
                </div>
              </div>
              <div className={"col-4"}>
                <div
                  className={`draw-tab-item ${pathname.endsWith(paths.scratchMyCards.url) ? "selected" : "border"}`}
                  onClick={() => {
                    navigate(paths.scratchMyCards.url);
                  }}
                >
                  <div>KARTLARIM</div>
                </div>
              </div>
              <div className={"col-4"}>
                <div
                  className={`draw-tab-item ${pathname.endsWith(paths.scartchRules.url) ? "selected" : "border"}`}
                  onClick={() => {
                    navigate(paths.scartchRules.url);
                  }}
                >
                  {/*<span className={"draw-tab-item-badge"}>Yakında</span>*/}
                  KURALLAR
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div className={"container mt-2"}>
          <div className="row bg-white p-2" style={{ borderRadius: "10px" }}>
            {params.subpage === undefined && (
              <>
                {scratchCards.map((card: any, key: number) => {
                  return (
                    <ScratchCardWidget
                      key={`scratch-card-${key}`}
                      setShownDraw={setShownDraw}
                      draw={card}
                    ></ScratchCardWidget>
                  );
                })}
              </>
            )}

            {params.subpage === "kartlarim" && (
              <>
                <ScratchCardMyCardsImported></ScratchCardMyCardsImported>
              </>
            )}

            {params.subpage === "kurallar" && (
              <>
                <ScratchCardRules></ScratchCardRules>
              </>
            )}
          </div>
        </div>
      )}

      <Modal
        isOpen={shownDraw !== null ? true : false}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            maxWidth: "calc(100vw - 80px)",
            width: "460px",
            maxHeight: "calc(100vh - 100px)",
            transform: "translate(-50%, -50%)",
            borderRadius: "1rem",
          },
          overlay: {
            zIndex: 2,
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(10px)",
          },
        }}
        onRequestClose={() => {
          setShownDraw(null);
        }}
      >
        {shownDraw && (
          <>
            <div className={"modal-close"}>
              <img src={iconClose} width={24} className={"float-right cursor-p "} onClick={() => setShownDraw(null)} />
            </div>
            <div className="modal-header">
              <h2 className="fw-bold fs-5 text-center text-primary">İkramiye Bilgileri</h2>
            </div>
            <div className="modal-body">
              <table className="table scratch-card-info">
                <thead>
                  <tr>
                    <th className={"text-left"}>İkramiyeler</th>
                    <th className="text-end">Adet</th>
                  </tr>
                </thead>
                <tbody>
                  {_.orderBy(
                    shownDraw.prizes,
                    function (o: any) {
                      return Number(o.approximate_price.replaceAll(".", "").replaceAll(",", ".").replaceAll("₺", ""));
                    },
                    ["desc"]
                  ).map((p: any, key: number) => {
                    return (
                      <tr key={"prize__scratch_" + key}>
                        <td className={"text-left"}>
                          <div className="ms-2">{p.approximate_price}</div>
                        </td>
                        <td className={"text-end"}>
                          <div className="me-2">{p.price_count}</div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="modal-ticket-footer text-end">
              <div className="wave"></div>
              <div className="body-row row">
                <div className="col-12">
                  <button
                    className="btn w-100 btn-primary"
                    onClick={() => {
                      setShownDraw(null);
                    }}
                  >
                    Kapat
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

export function ScratchCardMyCardsImported() {
  return <ScratchCardMyCards></ScratchCardMyCards>;
}

export function ScratchCardRules() {
  return (
    <>
      <div className="p-3">
        <h4 className="fs-5 fw-bold mb-3">Nasıl Oynanır?</h4>
        <ol>
          <li>İstediğiniz 4nala.com Kazı Kazan kartından en az 1 en fazla 100 adet satın alın.</li>
          <li>Aldığınız kartları KARTLARIM sayfasında görebilir ve kazıma işlemine başlayabilirsiniz.</li>
          <li>4nala.com Kazı Kazan kartı üzerinde parmağınızı kaydırarak kart kazıma işlemini yapabilirsiniz.</li>
          <li>
            Kartı otomatik kazı butonu yardımı ile kartınızı kazıyabilir veya TÜMÜNÜ OTOMATİK KAZI butonu ile hızlıca
            elinizdeki kartları kazıyabilirsiniz.
          </li>
        </ol>
      </div>

      <div className="p-3">
        <h4 className="fs-5 fw-bold mb-3">Oyun Kuralları?</h4>
        <ol>
          <li>4nala.com Kazı Kazan, geleneksel Kazı Kazan oyunu üzerine kurgulanmıştır.</li>
          <li>Her bir kartta 6 adet ikramiye rakamı yer alır.</li>
          <li>
            İkramiye rakamlarının yer aldığı alan kazındığında veya otomatik olarak açıldığında, açığa çıkan 6 adet
            ikramiye rakamından 3 adedinin aynı olması halinde, aynı ikramiye rakamlarının gösterdiği bir adet ikramiye
            kazanılır.
          </li>
          <li>Satın alınan kartların aynı gün 23:00'e kadar kazınması gerekmektedir.</li>
          <li>
            Gün sonuna kadar kazınmayan kartlar otomatik olarak sonuçlandırılacak ve ikramiye isabet eden kartların
            ikramiyeleri otomatik olarak bakiyeye yansıtılacaktır.
          </li>
          <li>
            Kazanılan ikramiye anında üyenin hesabına Puan (NP) olarak yüklenir. Uygulamamız ya da sitemiz üzerinden
            oynadığınız tüm oyun türlerinde oyun bedeli seçtiğiniz ödeme tipine göre bakiyenizden düşer.
          </li>
          <li>Puanların {"(NP)"} geçerlilik tarihi süresizdir.</li>
        </ol>
      </div>
    </>
  );
}

export function ScratchCardWidget(props: any) {
  const { draw, setShownDraw } = props;
  const [selectedQuantity, setSelectedQuantity] = useState<number>(0);
  const [showDrawModal, setShowDrawModal] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [scratchedCardCount, setScratchedCardCount] = useState<any>(0);
  const [showTotalWin, setShowTotalWin] = useState<any>(false);
  const [totalPayout, setTotalPayout] = useState<any>(0);
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const [sliderRef, setSliderRef] = useState<any>(null);

  // const showCards = (draw: any) => {
  //   const api = new ApiService();
  //   setShowTotalWin(false);
  //   setScratchedCardCount(0);
  //   setTotalPayout(0);
  //   let payload: any = {
  //     has_scratch: false,
  //     type: "get_draw",
  //     draw_id: draw.draw_id,
  //   };

  //   api.start("post", env.accounting_api + "/somos/api/kazi-kazan/kuponlarim/", payload, true).then((res: any) => {
  //     console.log(res);
  //     setShowDrawModal(res.data);
  //   });
  // };

  let screenWidth = window.innerWidth;
  if (screenWidth > 768) {
    screenWidth = 375;
  }
  let cardHeight = ((screenWidth - 40) / 335) * 406;
  const swiper = useSwiper();
  const handleScratchComplete = (c: any) => {
    const api = new ApiService();
    let payload: any = {
      type: "scratch",
      coupons: String(c.id),
    };
    api.start("post", env.accounting_api + "/somos/api/kazi-kazan/kazi/", payload, true).then((res: any) => {
      if (res && res.status) {
        // let d:any = dataList.find((e:any)=>e.draw_id===c.draw_id)
        // if(d){
        //   d.not_scratched_count -= 1;
        // }

        c.has_scratched = true;
        // timerSliderChanger = setTimeout(() => {
        //   sliderRef.slideNext();
        // }, 3000);

        c.selections.map((s: any, skey: number) => {
          let nonUnique = c.selections.filter((o: any) => {
            if (o.value === s.value) {
              return o;
            }
          }).length;
          if (nonUnique === 3) {
            setTotalPayout(totalPayout + Number(s.value));
            c.is_won = true;
            c.won_amount = s.value;
          }
        });
        forceUpdate();
      }
    });
    setScratchedCardCount(scratchedCardCount + 1);
    setTimeout(() => {
      if (scratchedCardCount + 1 == showDrawModal.length) {
        setShowTotalWin(true);
      }
    }, 3000);
  };

  const scratchAll = () => {
    const api = new ApiService();
    setLoading(true);
    var couponIds = showDrawModal.map((el: any, key: number) => {
      return el.id;
    });
    var totalWon = 0;
    let payload: any = {
      type: "scratch",
      coupons: couponIds.join(","),
    };
    api.start("post", env.accounting_api + "/somos/api/kazi-kazan/kazi/", payload, true).then((res: any) => {
      if (res && res.status) {
        showDrawModal.map((c: any, key: number) => {
          c.has_scratched = true;
          c.selections.map((s: any, skey: number) => {
            let nonUnique = c.selections.filter((o: any) => {
              if (o.value === s.value) {
                return o;
              }
            }).length;
            if (nonUnique === 3) {
              setTotalPayout(totalPayout + Number(s.value));

              c.is_won = true;
              c.won_amount = s.value;
            }
          });
          if (c.is_won) {
            totalWon += Number(c.won_amount);
          }
        });

        setScratchedCardCount(showDrawModal.length);
        setShowTotalWin(true);
        setTotalPayout(totalWon);
        forceUpdate();
        setLoading(false);
      }
    });
  };

  const buyTicket = (orderPayload: any) => {
    const api = new ApiService();
    api
      .start("post", env.accounting_api + "/somos/api/kazi-kazan/kupon-olustur/", orderPayload, true)
      .then((res: any) => {
        // console.log(res)
        if (res && res.status) {
          try {
            // @ts-ignore
            dataLayer.push({ ecommerce: null });
            // @ts-ignore
            dataLayer.push({
              event: "purchase",
              ecommerce: {
                value: (selectedQuantity == 0 ? 1 : selectedQuantity) * Number(draw.ticket_price), //kullanıcının son ödediği ücret
                transaction_id: Math.random(), //sipariş idsi
                currency: "TRY",
                coupon: "", //Eğer kullanıcı kupon kullandı ise kuponun ismi basılmalıdır.
                payment_type: orderPayload.unit == "0" ? "Balance" : "NP", //Ödeme yöntemi. Kredi Kartı, NP veya Balance
                purchase_type: "{{Kazı Kazan}}",
                items: [
                  {
                    item_id: draw.draw_id, // Kazı kazan veya Eşya Çekilişi kuponunun kodu
                    item_name: draw.title_abbreviated, //Eğer Kazı Kazan ise kazı kazan görselinin path'i basılmalıdır.
                    item_category: "{{Kazı Kazan}}",
                    price: draw.ticket_price,
                    quantity: selectedQuantity == 0 ? 1 : selectedQuantity,
                  },
                ],
              },
            });
          } catch (e: any) {
            console.log(e);
          }
          modalServiceSubject.next({
            case: "success",
            title: `${selectedQuantity == 0 ? "1" : selectedQuantity} Adet 4nala.com Kartı`,
            content: `<p class="fw-bold text-center">Satın alınan kartların en geç 23:00'e kadar kazınması gerekmektedir.</p>
                              <p class="text-center">
                                Kartların kazınmaması durumunda otomatik olarak kazınacak ve kazanan kartlar için ödüller 
                                otomatik olarak bakiyenize yansıtılacaktır.
                              </p>`,
            confirm: {
              cancel: {
                label: "Kartlarıma git",
                action: () => {
                  navigate(paths.scratch.url + "/kartlarim");
                },
                class: "btn btn-success w-100",
              },
              sure: {
                class: "btn btn-success w-100",
                label: "Hemen Kazı",
                action: () => {
                  navigate(paths.scratch.url + "/kartlarim/" + draw.draw_id);
                },
              },
            },
          });
        } else {
          if (res.error == "balance_error") {
            modalServiceSubject.next({
              case: "danger",
              title: "Bakiyeniz Yetersiz",
              width: "400px",
              content: `<p class="text-center">Hesabınıza bakiye yükleyebilirsiniz.</p>`,
              confirm: {
                cancel: {
                  label: "Diğer Ödeme Yöntemleri",
                  class: "btn-info  w-100",
                  action: () => {
                    window.location.href = paths.deposit.url;
                  },
                },
              },
            });
          } else {
            modalServiceSubject.next({
              case: "danger",
              title: `Hay aksi!`,
              width: 400,
              content: `<p class="text-center">${res.message}</p>`,
              confirm: {
                cancel: {
                  label: "Tamam",
                  class: "btn btn-danger w-100",
                },
              },
            });
          }
        }
      })
      .catch((e: any) => {
        exceptionModal(e);
      });
  };

  const buyScratchCard = () => {
    // if (!loginRequired(navigate)) {
    //   return false;
    // }

    modalServiceSubject.next({
      case: "info",
      width: "460px",
      title: "İşlemi Onayla",
      content: `<div class="text-center">
                  ${draw?.title_abbreviated}
                  <p class="text-center fw-bold">Büyük Ödül: ${draw.big_prize || "-"}</p>
                  <h4 class="text-center fw-bold">${selectedQuantity == 0 ? "1" : selectedQuantity} Kart tutarı:  ${(
        (selectedQuantity == 0 ? 1 : selectedQuantity) * Number(draw.ticket_price)
      ).toFixed(2)} TL</h4>
                  <p>Satılan Kartların iadesi yapılamaz.</p>
                  <p class="mb-0">
                    <label>
                      <input type="checkbox" id="id_unit_${draw._id}" name="terms" value="terms" /> NP Puan Harca<br />
                      <i  class="fs-7">Kullanılabilir NP Puan Bakiye: ${auth.member?.balance_point} NP</i>
                    </label>
                  </p>
                </p>`,
      confirm: {
        cancel: {
          label: "Vazgeç",
          class: "btn btn-primary w-100",
        },
        sure: {
          label: "Satın Al",
          class: "btn btn-warning w-100",
          action: () => {
            let psPoint: any = document.getElementById(`id_unit_${draw._id}`);

            let orderPayload: any = {
              draw_no: draw.no,
              unit: psPoint.checked ? 2 : 0,
              multiply: selectedQuantity == 0 ? 1 : selectedQuantity,
            };

            if (
              !psPoint.checked &&
              (selectedQuantity == 0 ? 1 : selectedQuantity) * Number(draw.ticket_price) >
                Number(auth.member.balance_debit) &&
              (selectedQuantity == 0 ? 1 : selectedQuantity) * Number(draw.ticket_price) <=
                Number(auth.member.balance_point)
            ) {
              setTimeout(() => {
                modalServiceSubject.next({
                  case: "danger",
                  title: `Bakiyeniz Yetersiz!`,
                  width: 400,
                  content: `<p class="text-center">TL bakiyeniz yetersiz NP kullamak ister misiniz?</p>`,
                  confirm: {
                    cancel: {
                      label: "Vazgeç",
                      class: "btn btn-danger",
                    },
                    sure: {
                      label: "NP Kullan",
                      class: "btn btn-success",
                      action: () => {
                        orderPayload.unit = 2;
                        buyTicket(orderPayload);
                      },
                    },
                  },
                });
              }, 500);
            } else {
              buyTicket(orderPayload);
            }
          },
        },
      },
    });
  };

  if (draw === undefined) return <></>;

  return (
    <>
      <div className="col-12 col-mobile-12 col-lg-6 mb-3">
        <div className="scratch-card">
          <div
            className="body"
            style={{
              backgroundImage: `url(${env.accounting_api}/media/${draw.photo})`,
              marginBottom: "0",
              marginTop: "-1rem",
            }}
          >
            <div className={"info text-end"} style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="btn btn-light fw-bold btn-sm rounded-pill"
                style={{
                  color: "#0d6efd",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  setShownDraw(draw);
                }}
              >
                {draw.ticket_price.toFixed(2)} TL
                <img style={{ color: "#0d6efd", fill: "#0d6efd" }} src={iconInfo} className={"ms-2"} />
              </button>
            </div>
            <div className="desciption">
              {/*<span>{draw.title_abbreviated}</span>*/}
              {/*<h2>{draw.big_prize||'-'}</h2>*/}
            </div>

            <div className="row mx-0 px-1 quantities">
              <div className="col-2 px-1">
                <button
                  onClick={() => {
                    setSelectedQuantity(10);
                  }}
                  className={`btn btn-sm w-100 fw-bold rounded-3 btn-outline-quantity ${
                    selectedQuantity === 10 ? "active" : ""
                  }`}
                >
                  10
                </button>
              </div>
              <div className="col-2 px-1">
                <button
                  onClick={() => {
                    setSelectedQuantity(25);
                  }}
                  className={`btn btn-sm w-100 fw-bold rounded-3 btn-outline-quantity ${
                    selectedQuantity === 25 ? "active" : ""
                  }`}
                >
                  25
                </button>
              </div>
              <div className="col-2 px-1">
                <button
                  onClick={() => {
                    setSelectedQuantity(50);
                  }}
                  className={`btn btn-sm w-100 fw-bold rounded-3 btn-outline-quantity ${
                    selectedQuantity === 50 ? "active" : ""
                  }`}
                >
                  50
                </button>
              </div>
              <div className="col-2 px-1">
                <button
                  onClick={() => {
                    setSelectedQuantity(100);
                  }}
                  className={`btn btn-sm w-100 fw-bold rounded-3 btn-outline-quantity ${
                    selectedQuantity === 100 ? "active" : ""
                  }`}
                >
                  100
                </button>
              </div>
              <div className="col-4 px-1">
                {/* <select
                  value={selectedQuantity}
                  className="btn btn-sm w-100 fw-bold rounded-3 btn-outline-quantity"
                  onChange={(e: any) => {
                    setSelectedQuantity(Number(e.target.value));
                  }}
                >
                  {Array.from(Array(100).keys()).map((n: any) => {
                    return (
                      <option key={n} value={n}>
                        {n == 0 ? "" : n} Adet
                      </option>
                    );
                  })}
                </select> */}
                <input
                  type="number"
                  min={0}
                  max={100}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value < 0) {
                      setSelectedQuantity(0);
                    } else if (value > 100) {
                      setSelectedQuantity(100);
                    } else {
                      setSelectedQuantity(value);
                    }
                  }}
                  value={selectedQuantity === 0 ? "" : selectedQuantity}
                  className="btn btn-sm w-100 fw-bold rounded-3 btn-outline-quantity"
                  placeholder="Adet"
                />
              </div>
            </div>
          </div>
          <div className="foot">
            <button
              onClick={() => {
                buyScratchCard();
              }}
              className="btn btn-primary fw-bold w-100 btn-buy"
            >
              Kart Al
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDrawModal !== null}
        onAfterOpen={() => {
          document.body.style.overflow = "hidden";
        }}
        onRequestClose={() => {
          document.body.style.overflow = "unset";

          setShowDrawModal(null);
        }}
        className={"scratch-card-modal"}
        style={{
          content: {
            top: "50%",
            left: "75%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            maxWidth: "calc(100vw - 80px)",
            width: "460px",
            maxHeight: "calc(100vh - 100px)",
            transform: "translate(-50%, -50%)",
            borderRadius: "1rem",
          },
          overlay: {
            zIndex: 2,
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(10px)",
          },
        }}
        contentLabel=""
      >
        <a
          className="modal-close"
          onClick={() => {
            document.body.style.overflow = "unset";
            setShowDrawModal(null);
          }}
        >
          <img src={iconClose} />
        </a>

        {loading ? (
          <div id="id_loaderAnimation" className="loader"></div>
        ) : (
          <>
            {showTotalWin ? (
              <>
                {totalPayout > 0 ? (
                  <h2 className="text-center fw-bold fs-4">Tebrikler</h2>
                ) : (
                  <h2 className="text-center fw-bold fs-4">Tekrar Dene</h2>
                )}
                <div className="scratch-card winner">
                  <div className="body p-4">
                    {totalPayout > 0 ? (
                      <div className="desciption">
                        <span>Toplam</span>
                        <h2>{totalPayout} ₺</h2>
                        <span>NP Puan </span>
                        <span>Kazandınız</span>
                      </div>
                    ) : (
                      <div className="desciption">
                        {/*<h2>Kaybettiniz ☹️</h2>*/}
                        <h2>Biletinize ikramiye çıkmadı</h2>
                      </div>
                    )}
                  </div>
                  <div className="foot">
                    <p>Kazanılan tutar hesabına NP Puan Olarak yatırılmıştır.</p>
                  </div>
                </div>
                {totalPayout > 0 && (
                  <div className="card rounded-4 mt-4">
                    <div className="card-header">
                      <h2 className={"won-card-header"}>Kazanan Kart Listesi</h2>
                    </div>
                    <div className="card-body won-tickets-area">
                      {showDrawModal &&
                        showDrawModal
                          .filter((el: any) => el.is_won == true)
                          .map((el: any, key: any) => {
                            return (
                              <div key={"won-tickets-" + key.toString()} className={"won-ticket-row"}>
                                <span>#{el.id}</span>
                                <span>#{el.won_amount}</span>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                )}
                <button
                  className="btn btn-custom w-100 mt-3"
                  onClick={() => {
                    document.body.style.overflow = "unset";
                    setShowDrawModal(null);
                    setShowTotalWin(false);
                  }}
                  id="btn_submit"
                >
                  Tekrar Dene
                </button>
                <button
                  className="btn btn-success w-100 mt-3 fw-bold"
                  onClick={() => {
                    document.body.style.overflow = "unset";
                    setShowDrawModal(null);
                    navigate(paths.scratch.url + "/kartlarim");
                  }}
                  id="btn_submit"
                >
                  Kartlarım
                </button>
              </>
            ) : (
              <>
                {showDrawModal && (
                  <h2 className="text-center fw-bold fs-6">
                    <span
                      style={{
                        fontSize: 14,
                        display: "block",
                      }}
                    >
                      {showDrawModal[0].draw?.title_abbreviated}
                    </span>
                    Büyük Ödül: {showDrawModal[0].draw?.big_prize || "-"}
                  </h2>
                )}
                {showDrawModal && (
                  <h2 className="text-center fw-bold fs-6">
                    {scratchedCardCount} / {showDrawModal.length}
                  </h2>
                )}

                {showDrawModal && (
                  <Swiper
                    spaceBetween={30}
                    id={`card-slider`}
                    allowTouchMove={false}
                    onSlideChange={(e: any) => {
                      // clearTimeout(timerSliderChanger);
                      // let ex: any = document.querySelectorAll('#card-slider');
                      // let wrapper: any = document.querySelectorAll('#card-slider .swiper-wrapper');
                      // wrapper[0].style.marginLeft = `unset`;
                      // wrapper[0].style.transform = `translate3d(${(ex[0].offsetWidth * -1 * e.activeIndex)}px, 0px, 0px);`;
                      // setTimeout(() => {
                      //   console.log(ex[0].offsetWidth)
                      //   wrapper[0].style.marginLeft = `${(ex[0].offsetWidth * -1 * e.activeIndex)}px`;
                      //   wrapper[0].style.transform = 'unset';
                      // }, 400);
                    }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                    }}
                    onInit={(ev: any) => {
                      // console.log(ev)
                      setSliderRef(ev);
                    }}
                  >
                    {showDrawModal &&
                      showDrawModal.map((el: any, key: number) => {
                        let nonUnique: any = 0;
                        let prizePayout: any = 0;

                        return (
                          <SwiperSlide id={`card-slider-items-${key}`} key={`card-slider-items-${key}`}>
                            <div className="px-3">
                              {/* @ts-ignore */}
                              <ScratchCard
                                {...{
                                  width: screenWidth - 40,
                                  height: cardHeight - 90,
                                  image: couponCover,
                                  finishPercent: 70,
                                  onComplete: () => handleScratchComplete(el),
                                }}
                              >
                                <div className="scratch-off-card">
                                  <img style={{ height: cardHeight - 90 }} src={couponBg} />
                                  <div className="row">
                                    {el.selections.map((s: any, skey: number) => {
                                      let nonUnique = el.selections.filter((o: any) => {
                                        if (o.value === s.value) {
                                          return o;
                                        }
                                      }).length;
                                      if (nonUnique === 3) {
                                        prizePayout = Number(s.value);
                                      }
                                      return (
                                        <div
                                          key={`${skey}-selection-${key}`}
                                          className={`col-6 ${
                                            nonUnique === 3 && el.has_scratched === true
                                              ? "won"
                                              : el.has_scratched === true && nonUnique == 2 && prizePayout == 0
                                              ? "semi-won"
                                              : el.has_scratched === false && s.has_scratched !== true
                                              ? "non-scratched"
                                              : ""
                                          }`}
                                          style={{
                                            height: (cardHeight - 90) / 3,
                                          }}
                                        >
                                          {Number(s.value).toFixed(0)}₺
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </ScratchCard>
                              {showDrawModal && scratchedCardCount != showDrawModal.length && (
                                <button
                                  className="btn btn-primary w-100 mt-2 fw-bold"
                                  onClick={() => {
                                    if (el.has_scratched == false) {
                                      scratchAll();
                                    }
                                    // else{
                                    //   sliderRef.slideNext();
                                    // }
                                  }}
                                  id="btn_submit"
                                >
                                  {el.has_scratched == false ? "Tümünü Kazı 1" : "Sıradaki Karta Geç"}
                                </button>
                              )}
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                )}

                {/*{showDrawModal && scratchedCardCount != showDrawModal.length &&*/}
                {/*    <button className="btn btn-success w-100 mt-3 fw-bold" onClick={() => {*/}
                {/*      scratchAll();*/}
                {/*    }} id="btn_submit">Tümünü Kazı*/}
                {/*    </button>}*/}

                {showDrawModal && (
                  <h2 className="text-center fw-bold fs-5 mt-3">
                    <span style={{ fontSize: 14, display: "block" }}>Aynı 3 sayıyı bul, ödülü kazan!</span>
                  </h2>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
}
