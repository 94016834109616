import React, { useEffect, useRef } from "react";
import "./zeplin-card.css";

import { gtmViewItem, useForceUpdate } from "../../../services/misc.functions";
import { auth, authSubject } from "../../../store/auth.store";
import { ApiService } from "../../../services/http.service";
import { env } from "../../../constants/global.vars";
import GameSelectWidget from "../widgets/game_select_widget";
import eSansWhite from "../../../assets/icons/e-sans-white.svg";
import backButton from "../../../assets/feather/chevron-left.svg";
import { isMobile } from "../../../App";
import paths from "../../../paths";
import { useNavigate } from "react-router-dom";

const iframeHtmlString = (config: { public_token: string; game_id: string; merchant_id: string; post_url: string }) => `
<body>
  <style>
    html,
    body {
      width: 100% !important;
      height: 100% !important;
      margin: 0;
      padding: 0;
    }
    #gameIFrame {
      width: 100% !important;
      height: 100% !important;
      border: none;
      text-align: center;
      background-color: Black;
    }
    #iframeContainer {
      width: 100%;
    }
  </style>
  <div id="iframeContainer"></div>
  <script
    src="https://static-cdn.betsolutions.com/casino/GameStarter/Prod/gameStarter.min.js?v=18092019053918"
    type="text/javascript"
  ></script>
  <script type="text/javascript">
    var config = {
      Token: "${config.public_token}",
      MerchantId: ${config.merchant_id},
      Lang: "tr-TR",
      GameId: ${config.game_id} ,
      ProductId: 3,
      IsFreeplay: 0,
      Platform: "desktop",
      IframeContainerId: "iframeContainer",
      IframeId: "gameIFrame",
      GameOpenType: "iframe",
      AuthUrl: "${config.post_url}",
      WindowWidth: 1280,
      ResizeWindow: true,
    };
    // @ts-ignore
    Charismatic.Casino.GameStarter.startGame(config);
  </script>
</body>
`;

function ZeplinGameCards() {
  const iframeContainerRef = useRef<HTMLIFrameElement>(null);
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();

  useEffect(() => {
    let auth_subscriber = authSubject.subscribe(() => {
      forceUpdate();
    });
    return () => {
      auth_subscriber.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let api = new ApiService();

    if (auth.authentication && auth.member && auth.member.id) {
      gtmViewItem([{ code: "zeplin", name: "Zeplin" }], "Oyunlar");
      api
        .start("get", env.accounting_api + "/games/betsolutions/api/get_member_token/", null, true)
        .then((res: any) => {
          if (res && res.status && iframeContainerRef.current) {
            const blobContent = new Blob([iframeHtmlString(res.data)], { type: "text/html" });
            iframeContainerRef.current.src = URL.createObjectURL(blobContent);
          }
        });
    }
  }, [iframeContainerRef.current]);

  useEffect(() => {
    if (isMobile) {
      const bottomBar = document.getElementById("mobile-footer-menu");
      const headers = document.getElementsByTagName("header");

      // Hide elements
      if (bottomBar) {
        bottomBar.style.display = "none";
      }

      if (headers.length > 0) {
        headers[0].style.display = "none";
      }

      // Cleanup function to restore elements
      return () => {
        if (bottomBar) {
          bottomBar.style.display = "flex";
        }

        if (headers.length > 0) {
          headers[0].style.display = "flex";
        }
      };
    }
  }, []);

  return (
    <div>
      {isMobile && (
        <div className="row p-0 m-0">
          <div className="col-3 d-flex align-items-center justify-content-center">
            <img
              src={backButton}
              height={25}
              style={{ marginRight: "0.5rem", position: "absolute", left: "10px", filter: "invert(100%)" }}
              onClick={() => navigate(paths.games.url)}
            />
          </div>

          <div className="col-6 d-flex flex-column justify-content-center align-items-center">
            <div className={"d-flex justify-content-center align-items-center mt-2 me-4"}>
              <img src={eSansWhite} height={20} style={{ marginRight: "0.5rem" }} />
              <h2 className={"text-white mb-0"} style={{ fontSize: "16px" }}>
                Şans Oyunları
              </h2>
            </div>
            <GameSelectWidget game_name="Zeplin" />
          </div>

          <div className="col-3 game-user-balance">
            <div className="lines">
              <span>{auth.member.balance_debit}₺</span>
              <span>{Number(auth.member.balance_point).toFixed(2)}P</span>
            </div>
          </div>
        </div>
      )}
      <iframe ref={iframeContainerRef} className={"iframeContainer-zeplin"} />
    </div>
  );
}

export default ZeplinGameCards;
