import React, {useEffect, useRef, useState} from "react";
import {ApiService} from "../../../services/http.service";
import {agfHistoryUrlPrefix, agfUrlPrefix} from "../../../constants/global.vars";
import {Storage} from "../../../services/localstorege.service";
import iconFather from "../../../assets/feather/father-at-yarisi.svg";
import iconClock from "../../../assets/feather/clock.svg";
import chart from "../../../assets/icons/increase.png";
import {isMobile} from "../../../App";
import {
    addToTJKBetSlip,
    agfBulletinData,
    betSlipActionSubject,
    isAdded,
    tjkBetSlipOptions
} from "../../../services/betslip.service";
import {availableBettingSubTypes, availableBettingTypes} from "../../../store/bulletin.store";
import {bulletinProvider} from "../../../services/bulletin.service";
import Modal from "react-modal";
import iconExit from "../../../assets/feather/x-circle.svg";
import {MobileBetslip} from "../../../shared/betslip/betslip";
import {useForceUpdate} from "../../../services/misc.functions";
import {Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


let agfInterval: any = null;

export function AGF(prop: any) {
    const selectBettingColumn = prop.selectBettingColumn;
    const [agfData, setAgfData] = useState<any>(Storage.get("agfData"))
    const [hippodrome, setHippodrome] = useState<any>(prop.hippodrome);
    const [shownWager, setShownWager] = useState<any>(0);
    const [shownMobileRun, setShownMobileRun] = useState<any>(0);
    const [showHorseStatistics, setShowHorseStatistics] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [labelData, setLabelData] = useState<any>(null);
    const [agfHistoryData, setAgfHistoryData] = useState<any>(null);
    const [title, setTitle] = useState<any>("");
    const forceUpdate = useForceUpdate();
    const chartColorMap: any = {
        0: 'rgba(186,0,255,1)',
        1: 'rgba(37,0,130,1)',
        2: 'rgba(0,255,114,1)',
        3: 'rgba(199,22,205,1)',
        4: 'rgba(39,71,50,1)',
        5: 'rgba(246,255,0,1)',
        6: 'rgba(52,159,0,1)',
        7: 'rgba(255,216,0,1)',
        8: 'rgba(0,138,255,1)',
        9: 'rgba(230,0,126,1)',
        10: 'rgba(71,54,198,1)',
        11: 'rgba(250,248,85,1)',
        12: 'rgba(189,0,71,1)',
        13: 'rgba(119,125,255,1)',
        14: 'rgba(50,151,39,1)',
        15: 'rgba(255,139,124,1)',
        16: 'rgba(255,30,0,1)',
        17: 'rgba(134,172,0,1)',
        18: 'rgba(108,91,178,1)',
        19: 'rgba(65,136,144,1)',
    }

    const getAgfData = () => {

        if (window.location.pathname.includes('agf')) {
            let api = new ApiService()
            api.start("get", agfUrlPrefix, null, false).then((res: any) => {
                if (res.status) {
                    setAgfData(res.data);
                    // if (showHorseStatistics != null) {
                    getAgfHistory();
                    // }
                    // console.log(agfInterval);
                    if (agfInterval === null) {
                        agfInterval = setInterval(getAgfData, 10 * 1000);
                    }
                }
            })
        } else {
            clearInterval(agfInterval);
        }

    }

    const getAgfHistory = () => {
        let api = new ApiService();
        api.start("get", agfHistoryUrlPrefix, null, false).then((res: any) => {
            if (res.status) {
                // for (const agfDatum of agfData) {
                //     for (const wager of agfDatum.wagers) {
                //         console.log(wager);
                //     }
                // }
                setAgfHistoryData(res.data);

            }
        })
    }

    useEffect(() => {


        let betSlipActionSubject_subscription = betSlipActionSubject.subscribe((res: any) => {
            forceUpdate();
        });
        return () => {
            betSlipActionSubject_subscription.unsubscribe();
        };
    }, []);


    useEffect(() => {
        if (!agfData) {
            getAgfData();
        }
    }, []);

    let eqStyles: any = {
        "1": {transform: 'scaleX(-1)', marginLeft: 5, filter: 'brightness(0)'},
        "2": {
            transform: 'scaleX(-1)',
            marginLeft: 5,
            filter: 'invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%) contrast(117%)'
        },
        "3": {transform: 'scaleX(-1)', marginLeft: 5},
    }

    const getMinimalName = (name: string) => {
        if (name.split(' ').length > 1) {
            let firstChar: any = name.charAt(0);
            return firstChar + '. ' + name.split(' ').slice(1, name.split(' ').length).join(' ');
        } else {
            return name;
        }
    }


    if (!hippodrome || !agfData) {
        return <></>
    }


    let agfWagers: any = agfData.find((el: any) => el._date === hippodrome._date && el.keyword === hippodrome.keyword)


    if (!agfData || !agfWagers || (agfData.length > 0 && agfWagers && agfWagers.wagers.length === 0)) {
        return <>
            <div className="page-widget">
                <p className="p-4 text-center"><img alt={"clock"} src={iconClock} width={64}/> <br/><br/>
                    Henüz AGF verileri açıklanmamış olabilir lütfen kısa bir süre sonra tekrar deneyiniz.</p>
            </div>
        </>
    }


    return (<>
        <div className="page-widget">
            <svg style={{display: 'none'}}>
                <defs>
                    <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
                </defs>
            </svg>
            <ul className="nav nav-tabs">
                {agfWagers.wagers.map((wager: any, keyRun: number) => {
                    return (
                        <li key={`runItem-${keyRun}`} className={`nav-item ${keyRun === shownWager ? 'active' : ''}`}>
                            <svg className="left" viewBox="0 0 80 60" preserveAspectRatio="none">
                                <use xlinkHref="#tabshape"></use>
                            </svg>
                            <a className="nav-link" onClick={() => {
                                setShownWager(keyRun);
                                agfBulletinData.selectedWager = keyRun;
                                agfBulletinData.label = agfWagers.wagers[keyRun].runs.map((r: any) => r.no).join('-');
                                setShownMobileRun((keyRun * 5))
                            }}>{wager.wager}</a>
                            <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                                <use xlinkHref="#tabshape"></use>
                            </svg>
                        </li>)
                })}
            </ul>

            {isMobile && <div className="d-flex px-2 mt-2">
                {agfWagers.wagers[shownWager].runs.map((r: any, key: number) => {
                        return (
                            <button className="btn btn-sm btn-primary w-100 p-1" onClick={() => {
                                setShownMobileRun(key + (shownWager * 5))
                            }} style={{marginLeft: 1, marginRight: 1}}>{key + 1}.Ayak</button>
                        )
                    }
                )}
            </div>}

            <div className={"w-100 d-inline-flex flex-nowrap possibles-table"}>
                {agfWagers.wagers[shownWager].runs.map((r: any, key: number) => {

                    if (isMobile && shownMobileRun !== (key + (shownWager * 5))) {
                        return <></>
                    }

                    let runFalseHorses: any = [];

                    return (
                        <div className="col-12 col-md-12 col-xl-2 p-1 runs" key={`agf-runs-r-${key}`}>
                            <div className="caption d-flex justify-content-between">
                                <div className={``}>{key + 1}. Ayak</div>
                                <div onClick={() => {
                                    var dataSet = [];

                                    var _labelData = agfHistoryData[`${agfWagers.keyword.toString().toLowerCase()}_${r.no}_${r.horses[0].no}`].map((el: any) => el.date.split(' ')[1]);
                                    for (const horse of r.horses) {
                                        var horseData = agfHistoryData[`${agfWagers.keyword.toString().toLowerCase()}_${r.no}_${horse.no}`];
                                        dataSet.push({
                                            label: isMobile ? horse.no : horse.name,
                                            data: horseData.map((el: any) => {
                                                return Number(el.ratio.replace(',', '.'))
                                            }),
                                            fill: false,
                                            borderColor: chartColorMap[horse.no],
                                            backgroundColor: chartColorMap[horse.no]

                                        });

                                    }
                                    setLabelData(_labelData);
                                    setData(dataSet);
                                    setTitle(`${agfWagers.hippodrome} ${key + 1}. Ayak ${tjkBetSlipOptions.hippodrome.runsMap[r.no].time}`)
                                    setShowHorseStatistics(true);
                                }}>
                                    <img src={chart} className={"invert-color"} width={21}/>
                                </div>
                            </div>
                            {r.horses.map((h: any, pkey: number) => {


                                let totalAgf = parseFloat(h.agf_ratio.replace(',', '.'));
                                let horseIsAdded = isAdded(Number(key + 1), tjkBetSlipOptions.hippodrome.runsMap[r.no], tjkBetSlipOptions.hippodrome.runsMap[r.no].horseMap[h.no]) !== false ? "horse-added-agf" : "";
                                if (h.stablemate !== null) {
                                    h.stablemate.map((s: any) => {
                                        if (s.run_status === false) {
                                            s.sstyle = eqStyles[h.stablemate[0].stablemate];
                                            runFalseHorses.push(s)
                                        }
                                    })
                                }

                                return (
                                    <>
                                        <div onClick={() => {
                                            let isExist6li = availableBettingTypes.find((b: any) => b.id === 18);
                                            if (isExist6li) {
                                                bulletinProvider.selectBettingType(hippodrome, isExist6li.id);
                                                var _selectSubType = availableBettingSubTypes.find((el:any)=>el.label === agfBulletinData.label)
                                                if (_selectSubType) {
                                                    bulletinProvider.selectBettingSubType(_selectSubType);
                                                    var run = tjkBetSlipOptions.hippodrome.runsMap[r.no];
                                                    var horse = run.horseMap[h.no];
                                                    if (run.status !== 2) {
                                                        addToTJKBetSlip(Number(key + 1), run, horse, "toggle");
                                                    }
                                                }
                                            }

                                        }}
                                             className={`line py-1 d-flex ${horseIsAdded} ${h.agf_order === 1 ? 'text-success' : ''}`}
                                             key={`agf-data-${pkey}`}>

                                            {h.stablemate === null &&
                                                <div style={{width: 30}}
                                                     className={`text-center`}>{h.agf_order}</div>}

                                            <div className={"d-flex flex-column w-100"}
                                                 style={{
                                                     marginRight: "20px",
                                                     marginLeft: h.stablemate !== null ? "25px" : "5px"
                                                 }}>
                                                <div
                                                    className={`text-start d-flex ${isMobile ? 'justify-content-between ' : ''}`}
                                                    style={{flex: "2"}}>
                                                    <div style={{
                                                        width: isMobile ? '40%' : 'unset',
                                                        marginLeft: isMobile ? '5px' : '0'
                                                    }} className={"text-start"}>
                                                    <span
                                                        className={`${!isMobile ? 'tooltip' : ''}`}>{!isMobile ? `${h.no} -` : ""} {isMobile && `${h.name} (${h.no})`}
                                                        {!isMobile && <div className={'tooltip-text'}>{h.name}</div>}

                                            </span>
                                                    </div>
                                                    <div>

                                                        <span className={"mx-1 fw-bold"}>(%{h.agf_ratio})</span>
                                                        {h.stablemate !== null ?
                                                            <img alt={"icon"} src={iconFather} width={9}
                                                                 className={`${false && h.run_status === false && 'black-to-red'}`}
                                                                 style={eqStyles[h.stablemate[0].stablemate]}/> : <></>} {h.run_status === false ? <><span
                                                        className="text-danger">(K)</span></> : <></>}
                                                    </div>


                                                </div>
                                                {h.stablemate !== null && h.stablemate.length > 0 &&
                                                    <div className="total-agf">%{h.stablemate_agf_ratio}</div>}
                                                {h.stablemate !== null && h.stablemate.length > 0 &&
                                                    <div className="stablemate-number">{h.agf_order}</div>}

                                            </div>
                                        </div>
                                        {h.stablemate !== null ?

                                            <>
                                                {h.stablemate.map((s: any, k: number) => {
                                                    totalAgf += parseFloat(s.agf_ratio.replace(',', '.'))
                                                    return (<>
                                                        <div onClick={() => {
                                                            // addToTJKBetSlip(Number(tjkBetSlipOptions.column), run, horse, "toggle");
                                                            // tjkBetSlipOptions.bettingType
                                                            let isExist6li = availableBettingTypes.find((b: any) => b.id === 18);
                                                            if (isExist6li) {
                                                                bulletinProvider.selectBettingType(hippodrome, isExist6li.id);
                                                                bulletinProvider.selectBettingSubType(availableBettingSubTypes[shownWager]);
                                                                var run = tjkBetSlipOptions.hippodrome.runsMap[r.no];
                                                                var horse = run.horseMap[s.no];
                                                                if (s.run_status) {
                                                                    addToTJKBetSlip(Number(key + 1), run, horse, "toggle");
                                                                }

                                                            }

                                                        }}
                                                             className={`line mt-0 py-1 d-flex ${isAdded(Number(key + 1), tjkBetSlipOptions.hippodrome.runsMap[r.no], tjkBetSlipOptions.hippodrome.runsMap[r.no].horseMap[s.no]) !== false ? "horse-added-agf" : ""} ${h.agf_order === 1 ? 'text-success' : ''}`}
                                                             key={`item-agf-stablemate-${k}-${key}`}>
                                                            <div
                                                                className={`text-start d-flex ${isMobile ? 'justify-content-between' : ''} w-100`}
                                                                style={{
                                                                    marginRight: "20px",
                                                                    marginLeft: h.stablemate !== null ? "25px" : "5px"
                                                                }}>
                                                                <div style={{
                                                                    width: isMobile ? '40%' : 'unset',
                                                                    marginLeft: isMobile ? '5px' : '0'
                                                                }} className={"text-start"}>
                                                                    <span
                                                                        className={`${!isMobile ? 'tooltip' : ''}`}>
                                                                        {!isMobile ? `${s.no} -` : ""} {isMobile && `${s.name} (${s.no})`}

                                                                        {!isMobile && <div
                                                                            className="tooltip-text">{s.name}</div>}

                                                                    </span>
                                                                </div>
                                                                <div>

                                                                    <span
                                                                        className={"mx-1 fw-bold"}>(%{s.agf_ratio})</span>
                                                                    {h.stablemate !== null ?
                                                                        <img alt={"icon"} src={iconFather}
                                                                             width={9}
                                                                             className={`${false && h.run_status === false && 'black-to-red'}`}
                                                                             style={eqStyles[h.stablemate[0].stablemate]}/> : <></>} {s.run_status === false ? <><span
                                                                    className="text-danger">(K)</span></> : <></>}
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </>)
                                                })}
                                            </>
                                            : <></>}
                                    </>
                                )

                            })}

                            {/*                            {runFalseHorses.map((h: any, ssKey: number) => {

                                return (
                                    <div className={`line py-1 d-flex ${h.agf_order === 1 ? 'text-success' : ''}`}
                                         key={`agf-horses-${ssKey}`}>
                                        <div style={{width: 30}} className={`text-center`}>-</div>
                                        <div className={`text-left me-4`}>

                                                 <span className="tooltip">{h.no}
                                                     <div className="tooltip-text">{h.name}
                                                         <br/> Jokey: {getMinimalName(h.jockey)}</div></span>


                                            - (%{h.agf_ratio}) <img alt={"father"} src={iconFather} width={9}
                                                                    className={`${false && h.run_status === false && 'black-to-red'}`}
                                                                    style={h.sstyle}/> <span
                                            className="text-danger">(K)</span>
                                        </div>
                                    </div>
                                )

                            })}*/}

                        </div>
                    )
                })}
            </div>
            {isMobile && tjkBetSlipOptions.bettingType != null &&
                <MobileBetslip selectBettingColumn={selectBettingColumn}></MobileBetslip>}
        </div>


        <Modal
            isOpen={showHorseStatistics !== null}
            onRequestClose={() => {
                setShowHorseStatistics(null);
                setData(null);
            }}
            ariaHideApp={false}
            style={{
                content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    width: "calc(100% - 40px)",
                    maxHeight: "calc(100% - 40px)",
                    height: "100%",
                    marginRight: "-50%",
                    maxWidth: " calc( 100% - 40px )",
                    padding: "15px",
                    transform: "translate(-50%, -50%)",
                    background: "var(--bs-white)",
                    borderRadius: "var(--bs-border-radius-xl)",
                    border: 0,
                },
                overlay: {
                    zIndex: 2,
                    backgroundColor: "rgba(0, 0, 0, 0.25)",
                    backdropFilter: "blur(10px)",
                },
            }}
            contentLabel=""
        >
            <div className="title modal-sticky-title">
                <div className={"float-end"}>
                    <img
                        alt={"iconExit"}
                        src={iconExit}
                        width={24}
                        className={"float-end cursor-pointer"}
                        onClick={() => {
                            setShowHorseStatistics(null);
                            setData(null);
                        }}
                    />
                </div>
                <h6>AGF SON DURUM</h6>
            </div>


            {data != null &&
                <AgfChart chartData={data} label={labelData} title={title}/>
            }

        </Modal>
    </>)
}


const AgfChart = (props: any) => {


    const data = {
        labels: props.label,
        datasets: props.chartData
    };

    const legendMargin = {
        id: "legendMargin",
        afterInit(chart: any, args: any, plugins: any) {
            const originalFit = chart.legend.fit;
            chart.legend.fit = function fit() {
                if (originalFit) {
                    originalFit.call(this)
                }
                return this.height += 25;
            }

        }
    };

    return (
        <div style={{height: "calc(100% - 20px)"}}>
            <Line data={data} plugins={[legendMargin]} options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: props.title,
                        color: '#1e3d98',
                        font: {
                            size: 14,
                        }
                    },
                    legend: {
                        position: 'top',
                        align: 'start',
                        labels: {
                            padding: 20,
                            usePointStyle: true,
                            pointStyle: "circle",
                            boxWidth: 40,
                            font: {
                                size: 14,
                                weight: 'bold'
                            }
                        },
                    },
                }
            }}/>
        </div>
    );
};
